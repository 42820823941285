import logo from "./logo.svg";
import "./App.css";
import { VenlyConnect } from "@venly/connect";
import { useCallback, useEffect, useRef, useState } from "react";

function App() {
  const dlaRef = useRef();
  const venlyConnect = useRef(
    new VenlyConnect("Testaccount", {
      environment: "staging",
      windowMode: "REDIRECT",
    })
  );

  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState("");
  const [session, setSession] = useState({});
  const [deepLink, setDeepLink] = useState("");

  const linkAndFetchWallets = useCallback(async () => {
    try {
      await venlyConnect.current.linkWallets();
      const wallets = await venlyConnect.current.api.getWallets({
        secretType: "MATIC",
        includeBalance: true,
      });

      setAddress(wallets[0].address);
    } catch (e) {
      console.error(e);
    }
  }, [setAddress]);

  const login = useCallback(async () => {
    try {
      const existingSession = await venlyConnect.current.checkAuthenticated();
      if (existingSession?.isAuthenticated) {
        setSession(existingSession);

        return;
      }
    } catch (_) {}

    try {
      console.log("Signing in...");
      const newSession = await venlyConnect.current.authenticate();
      setSession(newSession);
    } catch (e) {
      console.error(e);
    }
  }, [setSession]);

  const logout = useCallback(async () => {
    try {
      await venlyConnect.current.logout();
    } catch (_) {}

    setSession({});
    setAddress("");
    setBalance("");
  }, [setSession]);

  useEffect(() => {
    venlyConnect.current
      .checkAuthenticated()
      .then((existingSession) => {
        if (existingSession?.isAuthenticated) {
          setSession(existingSession);

          return;
        }
      })
      .catch(console.warn);
  }, []);

  useEffect(() => {
    if (!session || Object.keys(session).length === 0) {
      return;
    }

    console.log("PREPARING FOR DEEPLINK");

    const sessionData = {
      // idToken: session.auth.idToken,
      refreshToken: session.auth.refreshToken,
      token: session.auth.token,
      authServerUrl: session.auth.authServerUrl,
      clientId: session.auth.clientId,
      sessionId: session.auth.sessionId,
      subject: session.auth.subject,
    };

    const params = new URLSearchParams(window.location.search);
    const redirectUri = params.get("redirect_uri");
    const encodedSession = encodeURIComponent(JSON.stringify(sessionData));

    const deepLink = `${redirectUri}?session=${encodedSession}`;
    console.log(deepLink);

    if (params.get("is_app") === "true") {
      setDeepLink(deepLink);
    } else {
      linkAndFetchWallets();
    }
  }, [session, linkAndFetchWallets, setDeepLink]);

  useEffect(() => {
    if (address) {
      console.log(`Address: ${address}`);
      venlyConnect.current.api
        .getBalanceByAddress("MATIC", address)
        .then((balance) => {
          setBalance(balance);
        });
    }
  }, [address, setBalance]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a
          id="dla"
          ref={dlaRef}
          style={{ display: "none", visibility: "hidden" }}
        />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        {Object.keys(session).length > 0 ? (
          <>
            {address && <p>Address: {address}</p>}
            {balance && (
              <p>
                Balance: {balance.balance} {balance.secretType}
              </p>
            )}
            <button
              className="App-link"
              style={{ color: "black" }}
              href={"#"}
              onClick={logout}
            >
              Logout
            </button>
            {deepLink && (
              <button
                className="App-link"
                style={{ color: "black" }}
                href={deepLink}
              >
                Return to App
              </button>
            )}
          </>
        ) : (
          <button
            className="App-link"
            style={{ color: "black" }}
            href={"#"}
            onClick={login}
          >
            Login
          </button>
        )}
      </header>
    </div>
  );
}

export default App;
